.wrapped_box{
    display: flex;
    height: 683px;
    width: 100%;
}
.wrapped_box .first_section{
    margin-top: 125px;
}
.wrapped_box .first_section a{
    text-decoration: none;
}
.wrapped_box .first_section h1{
    color: var(--New-Light-Blue-348CCB, #348CCB);
    font-family: "Bud Light Easy Cond";
    font-size: 64px;
    font-style: normal;
    font-weight: 800;
    line-height: 100%; /* 64px */
    letter-spacing: 0.64px;
    text-transform: uppercase;
}
.wrapped_box .first_section p{
    color: var(--Cool-Blue, #348CCB);
    font-family: "Bud Light Easy Cond";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 115%; /* 32.2px */
    margin-top: 32px;
    width: 650px;
}
.wrapped_box .first_section button{
    display: flex;
    width: 226px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 48px;
    background: var(--New-Light-Blue-348CCB, #348CCB);
    color: var(--font-color-dark-surface-primary, #FFF);
    text-align: center;
    font-family: "Bud Light Easy Cond";
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 150% */
    letter-spacing: 2.24px;
    text-transform: uppercase;
    border: unset;
    margin-top: 24px;
}

.wrapped_box .second_section img{
    position: absolute;
    right: 0;
    width: 665px;
}
@media only screen and (max-width: 1200px) {
    .wrapped_box{
        display: block;
        margin: auto;
        width: 100%;
        height: 900px;
    }
    .wrapped_box .first_section{
        width: 100%;
        margin-top: 12px;
    }
    .wrapped_box .first_section h1{
        font-size: 48px;
    }
    .wrapped_box .first_section button{
        margin: auto;
    }
    .wrapped_box .first_section p{
        font-size: 20px;
        width: unset;
    }
   .second_section img{
        position: unset;
    }
}
@media only screen and (max-width: 992px) {
     .second_section img{
        width: 100%;
    }
}

@media only screen and (max-width: 764px) {
    .wrapped_box{
        height: 350px;
    }
}
@media only screen and (max-width: 500px) {
    .wrapped_box{
        height: 550px;
    }
    .wrapped_box .first_section button{
        margin-top: 50px;
    }
}