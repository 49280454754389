.age_gate_container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
  }
  
  .age_gate_content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .age_gate_input_container {
    width: 60%;
    padding: 10px;
    height: 100vh;
    display: flex;
    align-items: center;
    background: var(--new-light-blue-348-ccb, #348ccb);
  }
  
  .age_gate_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .age_gate_title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
  }
  
  .age_gate_responsibility {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
    padding: 10px;
  }
  
  .age_gate_responsibility_title {
    color: #fff;
    text-align: center;
    font-family: Bud Light Easy Cond;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.48px;
  }
  
  .age_gate_responsibility_desc {
    color: #fff;
    text-align: center;
    font-family: Bud Light Easy Cond;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
  }
  
  .age_gate_header {
    color: var(--font-color-dark-surface-primary, #fff);
    text-align: center;
    font-family: Bud Light Easy Cond;
    font-size: 54px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }
  
  .age_gate_desc {
    color: var(--font-color-dark-surface-primary, #fff);
    text-align: center;
    font-family: Bud Light Easy Cond;
    font-size: 17px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    text-transform: uppercase;
  }
  
  .age_gate_bg_container {
    background-image: url(../../../assets/image/ageGate/AgeGateCanImage.webp);
    width: 40%;
    height: 100vh;
    background-size: cover;
    background-position: center;
  }
  
  .age_gate_img_container {
    width: 100%;
    height: 100%;
  }
  
  .age_gate_error_text p {
    font-family: Bud Light Easy Cond;
    font-size: 1.125rem;
    line-height: 155%;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: red;
  }
  
  .age_gate_button {
    display: flex;
    width: 148px;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 48px;
    background: var(--greys-white, #fff);
    border: none;
  
    color: var(--new-light-blue-348-ccb, #348ccb);
    text-align: center;
    font-family: Bud Light Easy Cond;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 150% */
    letter-spacing: 2.24px;
    text-transform: uppercase;
  }
  
  @media (max-width: 900px) {
    .age_gate_logo {
      width: 60%;
      margin-top: 10px;
    }
  
    .age_gate_content {
      flex-direction: column;
    }
  
    .age_gate_input_container {
      width: 100%;
    }
  
    .age_gate_bg_container {
      display: none;
    }
  
    .age_gate_title {
      margin: 20px 0;
    }
  
    .age_gate_desc {
      font-size: 14px;
    }
  }
  
  @media (max-height: 450px) {
    .age_gate_logo {
      width: 20%;
    }
    .age_gate_title {
      margin: 10px 0;
    }
    .age_gate_header {
      font-size: 24px;
    }
    .age_gate_desc {
      margin: 0;
    }
    .age_gate_responsibility {
      margin: 0;
    }
  }
  
  
input[type='number'] {
  -moz-appearance: textfield !important; /* Firefox */
  appearance: textfield !important; /* Other modern browsers */
  /* Remove arrow controls for IE 11 and Edge */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    display: none !important;
  }
}