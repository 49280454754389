.footer-link {
  text-decoration: none;
  width: 31px;
  height: 24px;
  font-family: "Neue Haas Grotesk Text Pro roman" !important;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
  align-items: center !important;
  text-align: center !important;
}

.css-bqgeif {
  height: auto !important;
}

.footer-link img {
  width: auto;
  height: 24px;
  /* padding: 0px 12px; */
  margin: 0 12px;
}

.link {
  margin: 0px 20px !important;
}

.footer-copyright {
  font-family: "Neue Haas Grotesk Text Pro" !important;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  padding-top: 32px;
  text-align: center;

  color: rgba(255, 255, 255, 0.75);
}

.footer-links {
  padding-top: 64px;
  padding-bottom: 0px;
}

/* .footerContainer :global(.container) {
  max-width: 1200px !important;
} */

.nav-left {
  list-style-type: none !important;
  padding-left: 10px !important;
}

@media (min-width: 1001px) {
  .desktop-footer-responsive {
    display: block;
  }

  .pad-footer-responsive {
    display: none;
  }

  .iphone-footer-responsive {
    display: none;
  }
}

@media (max-width: 1000px) {
  .desktop-footer-responsive {
    display: none;
  }

  .pad-footer-responsive {
    display: block;
  }

  .iphone-footer-responsive {
    display: none;
  }

  .responsive-footer-link {
    color: black !important;
  }

  .other-link {
    margin-left: 0px !important;
  }
}

@media only screen and (min-width: 601px) {
  .responsive-footer-link {
    display: flex;
  }
}

@media (max-width: 500px) {
  .desktop-footer-responsive {
    display: none;
  }

  .pad-footer-responsive {
    display: none;
  }

  .iphone-footer-responsive {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media (max-width: 400px) {
  .desktop-footer-responsive {
    display: none;
  }

  .pad-footer-responsive {
    display: none;
  }

  .iphone-footer-responsive {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
  }
}
footer{
  width: 100%;
  background-color: #348CCB;
  padding-bottom: 50px;
}
footer .footer_parent_box{
  display: flex;
  justify-content: space-between;
  padding-top: 64px;
}
footer .footer_parent_box  .logo_section{
  margin-left: 90px;
}

footer .footer_parent_box  .logo_section .logo{
  object-fit: contain;
  width: 206px;
}
footer .footer_parent_box .logo_section .logo_box img{
  width: 24px;
  object-fit: contain;
  margin-top: 25px;
}
footer .footer_parent_box .logo_section .logo_box a:not(:first-child){
  margin-left: 30px;
}
footer .footer_parent_box .footer_menu_box{
  display: flex;
  width: calc(100% - 30px);
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 123px;
}
footer .logo_box {
  display: flex;
}
.twiter_box{
  width: 21px!important;
}
footer .footer_parent_box .footer_menu_box div:not(:last-child){
  margin-right: 10px;
}
/* footer .footer_parent_box .footer_menu_box div:first-child{
 margin-left: 123px;
} */
footer .footer_parent_box .footer_menu_box h6{
  color: #FFF;
  font-family: Titling Gothic FB Compressed;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;
  text-transform: uppercase;
}
footer .footer_parent_box .footer_menu_box ul{
  padding-left: unset!important;
}
footer .footer_parent_box .footer_menu_box button{
  display: flex;
  width: 235px;
  height: 48px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 8px;
  border: 1px solid #fff;
  text-align: center;
  background: transparent;
  color: #ffff;
  margin-top: 16px;
  /* Button Text/base */
  font-family: Titling Gothic FB Narrow;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px; /* 150% */
  letter-spacing: 2.24px;
  text-transform: uppercase;
}
footer .footer_parent_box .footer_menu_box ul li{
  color: #fff;
  list-style: none;
  margin-left: unset;
  font-family: Bud Light Easy Cond;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  margin-bottom: 10px;
  text-decoration: none;
  text-transform: uppercase;
}

footer .line_box{
  border: 1px solid #fff;
  width: 100%;
  margin-top: 64px;
}
footer  ul li a{
  color: #fff!important;
  text-decoration: none!important;
}
footer .enjoy_box {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}
footer .enjoy_box:last-child {
 margin-top: 32px;
}
footer .enjoy_box p  {
  color: #fff;
  font-family: Bud Light Easy Cond;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  text-align: center;

  line-height: 24px; /* 184.615% */
}
footer .enjoy_box p a  {
  color: #fff;
  text-decoration: none;
}
/* footer .enjoy_box ul {
  display: flex;
  list-style: none!important;
  padding-left: unset!important;
} */
/* footer .enjoy_box ul li {
  font-family: Neue Haas Grotesk Text Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 45px;
  line-height: 24px;
  color: #fff;
  list-style: none;
  padding-left: unset!important;
} */
@media (max-width: 992px) {
  .footer_parent_box{
    display: block!important;
  }
  .logo_section{
    text-align: center;
    margin-left: unset!important;
  }
  .footer_menu_box{
    margin-left: 25px!important;
    margin-top: 48px!important;
  }
  .logo_box{
    justify-content: center;
  }
  .enjoy_box{
    display: flex!important;
    align-items: center!important;
    margin-top: 45px!important;
    flex-direction: column-reverse!important;
  }
  footer .enjoy_box ul {
    display: block
  }
  footer .enjoy_box li{
    margin-bottom: 16px;
    text-align: center;
    margin-left: unset!important;
  }
  footer .enjoy_box br{
    display: none;
  }
  footer .enjoy_box p  {
    text-align: center;
  }
}