.top-header {
  background-color: var(--mainColor) !important;
  color: white !important;
  position: fixed;
  z-index: 99999 !important;
  width: 100%;
  height: 75px;
}

.hamburger:active {
  display: none !important;
}

.mobile-discord-btn:hover {
  color: white !important;
}
.navbar-brand-logo-section{
  display: flex;
  align-items: center;
}

.navbar-brand-logo-section img {
  height: 32px;
}

.top-header a {
  color: white !important;
  font-family: Bud Light Easy Cond;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.1em !important;
}

.nav-2 {
  padding: 10px 0px !important;
}

.nav-1 li a {
  padding: 0 !important;
  margin: 0.5rem !important
}

.nav-1 li:nth-of-type(even) a {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.nav-2 :global(.nav-link) {
  padding-top: 0.5rem !important;
  padding-bottom: 0 !important;
}
.header-spinner-body{
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgb(245 245 245 / 15%) !important;
  z-index: 99999 !important;

}
.popup-body{
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgb(245 245 245 / 25%) !important;
  z-index: 99;
}
.popup {
  position: relative;
  background-color: #5b63d3 !important;
  width: 600px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
  border: 1px solid #061148;
}
.spinner {
  width: 4em;
  height: 4em;
  border: 0.5em solid rgba(0, 0, 0, 0.1);
  border-left-color: #010f57 !important;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 20px;
  z-index: 9999999 !important;
}
.form-gro input {
  font-size: 18px;
  background-color: none !important;
  border-radius: 10px;
  padding: 10px !important;
  width: 400px;
  margin-bottom: 30px;
  border: none !important;
  padding-left: 40px !important;
  margin-top: 30px;
}

.header-responsive-navbar {
  padding-left: 0;
  padding-right: 0;
}

.login-button {
  width: 200px;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  color: white !important;
  padding: 10px !important;
  margin-bottom: 30px;
  background-color: #061148 !important;
}
.connect-btn{
  text-align: center;
}
.login-button:hover{
  background-color: #132063 !important;
}
.iconposition{
  position: relative;
}
.closeicon {
  position: absolute;
  top: 12px;
  right: 29px;
  font-size: 31px;
  /* border: 1px solid white; */
  border-radius: 100px;
  padding: 5px;
  cursor: pointer;
}
.emailicon{
  position: absolute;
  height: 24px;
  width: 20px;
  top: 41px;
  left: 7px;
  color: #061148;
}
.message{
  font-size: 20px;
  font-weight: 600;
}


.spinner {
  width: 4em;
  height: 4em;
  border: 0.5em solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.nav-1 {
  display: flex !important;
}
.nav-2 {
  display: none !important;
  padding: 30px 0px;
}

@media only screen and (min-width: 1200px) {
  /* .nav-1 {
    display: flex !important;
  } */

  /* .top-header :global(.container) {
    max-width: 1200px !important;
  } */

  /* .nav-2 {
    display: none !important;
    padding: 30px 0px;
  } */
}

@media only screen and (max-width: 1200px) {
  .top-header :global(.navbar-nav) {
    background-color: var(--mainColor);
    margin-left: 0px;
  }

  .nav-2 {
    visibility: visible !important;
    display: flex !important;
  }

  .top-header :global(.collapse):not(:global(.show)) {
    display: none !important;
  }

  .nav-1 {
    display: none !important;
  }

  .meta-link-mobile {
    left: 36% !important;
  }

  .top-header :global(.css-zjuml4) {
    display: block !important;
  }
  .popup{
    width: 475px !important;
    height: 250px !important;
  }
  .top-header :global(.navbar-toggler) {
    display: block !important;
    border-color: white;
  }
}
@media only screen and (max-width: 1024px) {
.popup{
  width: 495px;
  height: 380px;
}
}
@media only screen and (min-width: 601px) {
  .header-responsive-navbar {
    width: 150px !important;
    padding-left: 0 !important;
  }
}
@media only screen and (max-width: 500px) {
  .meta-link-mobile {
    left: 36% !important;
  }

  .responsive-lg-hide {
    display: black !important;
  }
  .popup{
    width: 400px !important;
  }
  .login-button{
    width: 330px !important;
  }
  .form-gro input{
    width: 330px !important;
  }
}

@media only screen and (max-width: 400px) {
  .meta-link-mobile {
    left: 46% !important;
  }
  .popup{
    width: 370px;
    height: 250px;
  }
}
@media only screen and (max-width: 375px) {
  .popup{
    width: 350px !important;
    height: 250px;
  }
}

.top-header :global(.btn-info) {
  --bs-btn-color: transparent!important;
  --bs-btn-bg: transparent!important;
  --bs-btn-border-color: transparent!important;
  --bs-btn-hover-color: transparent!important;
  --bs-btn-hover-bg: transparent!important;
  --bs-btn-hover-border-color: transparent!important;
  --bs-btn-focus-shadow-rgb: transparent!important;
  --bs-btn-active-color: transparent!important;
  --bs-btn-active-bg: transparent!important;
  --bs-btn-active-border-color:transparent!important;
  --bs-btn-active-shadow: inset 0 3px 5px transparent!important;
  --bs-btn-disabled-color: transparent!important;
  --bs-btn-disabled-bg: transparent!important;
  --bs-btn-disabled-border-color:transparent!important;
}

.top-header :global(.navbar) {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: transparent !important;
  --bs-navbar-hover-color: transparent !important;
  --bs-navbar-disabled-color: transparent !important;
  --bs-navbar-active-color: transparent !important;
  --bs-navbar-brand-padding-y: 0.3125rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.25rem;
  --bs-navbar-brand-color: transparent !important;
  --bs-navbar-brand-hover-color: transparent !important;
  --bs-navbar-nav-link-padding-x: 0.5rem;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
}
