:root {
  --mainColor: #348ccb;
  --secondColor: #abc0d5;
  --icy-white: #eaeaf1;
  font-size: 16px !important;
}

html {
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

h1 {
  font-family: "Titling Gothic FB Compressed";
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 80px;
}

body {
  background-color: rgb(255, 255, 255) !important;
  color: var(--mainColor) !important;
  overflow-x: hidden !important;
  height: auto;
}

.responsive-hide {
  display: block;
}

.responsive-show {
  display: none !important;
}

.responsive-flex-show {
  display: none !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #7c91ff;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #7c85b5;
}

@font-face {
  font-family: "Bud Light Easy Cond";
  font-weight: 400;
  src: local("BudLightEasyCond-Book"),
    url("./Fonts/BudLightEasyCond-Book.otf")
      format("truetype");
}

@font-face {
  font-family: "Bud Light Easy Cond";
  font-weight: 800;
  src: local("BudLightEasyCond-XBold"),
    url("./Fonts/BudLightEasyCond-XBold.otf")
      format("truetype");
}




@font-face {
  font-family: "Titling Gothic FB Compressed";
  src: local("Titling Gothic FB Compressed"),
    url("./Fonts/Titling\ Gothic\ FB\ Compressed\ Medium.otf")
      format("truetype");
}

@font-face {
  font-family: "Titling Gothic FB Narrow";
  src: local("Titling Gothic FB Narrow"),
    url("./Fonts/Titling\ Gothic\ FB\ Narrow\ Standard.otf") format("truetype");
}
@font-face {
  font-family: "Titling Gothic FB Skyline";
  src: local("Titling Gothic FB Skyline"),
    url("./Fonts/TitlingGothicFBSkyline-Medium.otf") format("truetype");
}

@font-face {
  font-family: "Neue Haas Grotesk Text Pro";
  src: local("TNeue Haas Grotesk Text Pro"),
    url("./Fonts/NeueHaasDisplay-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Neue Haas Grotesk Text Pro meduim";
  src: local("Neue Haas Grotesk Text Pro meduim"),
    url("./Fonts/NeueHaasDisplay-Mediu.ttf") format("truetype");
}
@font-face {
  font-family: "Neue Haas Grotesk Text Pro roman";
  src: local("Neue Haas Grotesk Text Pro roman"),
    url("./Fonts/NeueHaasDisplay-Roman.ttf") format("truetype");
}

@media only screen and (max-width: 1399px) {
  .container {
    max-width: 1200px !important;
  }
}

@media only screen and (max-width: 900px) {
  .responsive-hide {
    display: none !important;
  }

  .responsive-show {
    display: block !important;
  }

  .responsive-flex-show {
    display: flex !important;
  }

  .m-10 {
    margin-top: 50px !important;
  }
}

@media only screen and (max-height: 250px) {
  .responsive-flex-show-element {
    display: none !important;
  }
}
